import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import apiendpoint from "../apiendpoint.json";

function MobileInput() {
  const [formData, setFormData] = useState({
    mobile: "",
  });
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      mobile: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      ...formData,
      NameUser: "",
      Email: "",
      Subject: "",
      Message: "",
    };

    try {
      const response = await fetch(apiendpoint.submitUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success("Mobile number submitted successfully!");
        setFormData({ mobile: "" });
      } else {
        toast.error("Failed to submit the mobile number.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the mobile number.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mx-auto max-w-6xl flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 py-6 px-4 bg-opacity-85 opacity-85">
          {/* Input Field */}
          <input
            type="text"
            name="mobile"
            className="w-full rounded-md px-6 py-3 max-w-full md:max-w-2xl bg-opacity-85 opacity-85 placeholder:text-sm placeholder:text-[#6E6E6E] border-2 border-[#6E6E6E] focus:outline-none focus:ring-2 focus:ring-[#FFBF00] text-lg"
            placeholder="Mention your mobile number.."
            value={formData.mobile}
            onChange={handleChange}
          />
          {/* Submit Button */}
          <button
            type="submit"
            className="w-full md:w-auto px-8 py-3 sm:w-auto bg-[#FFBF00] text-2xl text-white bg-opacity-95 opacity-95 rounded-md font-semibold hover:bg-yellow-600 focus:outline-none"
            disabled={loading}
          >
            {loading ? "Sending..." : "Submit"}
          </button>
        </div>
      </form>
      <Toaster position="top-center" />
    </>
  );
}

export default MobileInput;
