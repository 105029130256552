import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import logo from "../assets/mindmatrix.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItems = [
    { name: "Home", path: "home" },
    { name: "About Us", path: "mindfeatures" },
    { name: "Blogs", path: "blogs" },
    { name: "Culture", path: "culture" },
    { name: "Services", path: "services" },
    { name: "Contact", path: "contact" },
  ];

  return (
    <nav className="bg-white py-1 transition-shadow duration-300 border-b-4 border-transparent">
      <div className="flex flex-wrap items-center justify-between max-w-7xl px-4 mx-auto">
        <Link to="/schoolerp" className="flex items-center">
          <div className="relative w-auto">
            <img
              src={logo}
              alt="Mindmatrix"
              className="md:h-16 lg:h-20 xl:h-20 h-16 w-auto object-contain"
            />
          </div>
        </Link>
        <div className="flex items-center lg:order-2">
          <button
            type="button"
            className="inline-flex items-center p-2 ml-1 text-sm text-[#6E6E6E] rounded-lg lg:hidden focus:outline-none focus:ring-2 focus:ring-[#FFBF00]"
            aria-controls="mobile-menu-2"
            aria-expanded={isOpen}
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className={`w-4 h-4 ${isOpen ? "hidden" : "block"}`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className={`w-4 h-4 ${isOpen ? "block" : "hidden"}`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className="items-center justify-between w-full hidden lg:flex lg:w-auto lg:order-1"
          id="desktop-menu"
        >
          <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 cursor-pointer items-center">
            {menuItems.map((item) => (
              <li key={item.name}>
                <ScrollLink
                  to={item.path}
                  smooth={true}
                  duration={500}
                  className={`block py-2 pl-3 pr-4 rounded ${
                    item.name === "Home"
                      ? "text-white text-lg font-normal bg-[#FFBF00] lg:bg-transparent lg:text-[#FFBF00] border-b-2 border-[#FFBF00]"
                      : "text-[#6E6E6E] text-lg font-normal border-b border-[#6E6E6E] hover:bg-[#F3F3F3] lg:hover:bg-transparent lg:border-0 lg:hover:text-[#FFBF00]"
                  } lg:p-0`}
                  aria-current={item.name === "Home" ? "page" : undefined}
                >
                  {item.name}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="items-center justify-between w-full lg:hidden mt-4"
              id="mobile-menu-2"
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
              transition={{ duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }}
            >
              <ul className="flex flex-col font-medium">
                {menuItems.map((item, index) => (
                  <motion.li
                    key={item.name}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.1 * index }}
                  >
                    <ScrollLink
                      to={item.path}
                      smooth={true}
                      duration={500}
                      className={`block py-2 pl-3 pr-4 rounded ${
                        item.name === "Home"
                          ? "text-white text-lg font-normal bg-[#FFBF00] lg:bg-transparent lg:text-[#FFBF00] border-b-2 border-[#FFBF00]"
                          : "text-[#6E6E6E] text-lg font-normal border-b border-[#6E6E6E] hover:bg-[#F3F3F3] lg:hover:bg-transparent lg:border-0 lg:hover:text-[#FFBF00]"
                      } lg:p-0`}
                      aria-current={item.name === "Home" ? "page" : undefined}
                    >
                      {item.name}
                    </ScrollLink>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
}

export default Navbar;
