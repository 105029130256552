import React from "react";
import bg from "../assets/hero-bg.gif"; 
import MobileInput from "./MobileInput";

function Hero() {
  return (
    <div>
      <section className="w-full">
        
        <div className="w-full min-h-[40vh] sm:min-h-[50vh] md:min-h-[60vh] relative flex flex-col justify-center items-center overflow-hidden">
          
          <img
            src={bg}
            alt="Background"
            className="w-full h-full object-cover"
          />

          
          <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-10 pt-16 sm:pt-24 md:pt-32">
            <div className="w-full mx-auto z-50 text-center text-white px-6 sm:px-8 md:px-12">
          
              <MobileInput />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
