import React from "react";

function Services() {
  return (
    <section>
      <div className="bg-gray-50 p-4 min-h-screen border-b-8 border-gray-500 relative">
        {/* Background effect */}
        <div
          aria-hidden="true"
          className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-20"
        >
          <div className="blur-[106px] h-56 bg-gradient-to-br to-indigo-600 from-blue-500"></div>
          <div className="blur-[106px] h-32 bg-gradient-to-r from-teal-400 to-cyan-600"></div>
        </div>

        {/* Main content */}
        <div className="max-w-7xl mx-auto px-2 md:px-12 xl:px-4">
          <div className="md:w-2/3 lg:w-1/2 mt-12 text-[#6E6E6E] text-center mx-auto">
            <h2 className="my-8 text-3xl font-bold text-[#FFBF00] md:text-5xl">
              Our <span className="text-[#6E6E6E]">Services</span>
            </h2>
            <p className="text-gray-600 text-lg">
              Explore our diverse services, from web development to content creation, designed to cater to your unique needs.
            </p>
          </div>

          {/* Services grid */}
          <div className="mt-16 grid divide-x divide-y divide-gray-700 overflow-hidden rounded-3xl border text-gray-600 border-gray-700 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
            {/* Service 1 */}
            <div className="group relative bg-gray-800 transition-all duration-300 ease-in-out hover:z-[1] hover:shadow-xl hover:shadow-gray-600/10 hover:bg-[#1E3A8A]">
              <div className="relative space-y-8 py-12 p-8">
                <img
                  src="https://www.svgrepo.com/show/164986/logo.svg"
                  loading="lazy"
                  width="200"
                  height="200"
                  className="w-16 h-16 rounded-full mx-auto"
                  style={{ color: "transparent" }}
                  alt="Software Consultancy"
                />
                <div className="space-y-2 text-center">
                  <h5 className="text-xl font-semibold text-white transition group-hover:text-[#FFBF00]">
                    Software Consultancy
                  </h5>
                  <p className="text-gray-300">
                    We design software systems customized for specific tasks or industries, making processes more efficient.
                  </p>
                </div>
              </div>
            </div>

            {/* Service 2 */}
            <div className="group relative bg-gray-800 transition-all duration-300 ease-in-out hover:z-[1] hover:shadow-xl hover:shadow-gray-600/10 hover:bg-[#1E3A8A]">
              <div className="relative space-y-8 py-12 p-8">
                <img
                  src="https://www.svgrepo.com/show/120853/logo.svg"
                  loading="lazy"
                  width="200"
                  height="200"
                  className="w-16 h-16 rounded-full mx-auto"
                  style={{ color: "transparent" }}
                  alt="Content Services"
                />
                <div className="space-y-2 text-center">
                  <h5 className="text-xl font-semibold text-white transition group-hover:text-[#FFBF00]">
                    Content Services
                  </h5>
                  <ul className="text-gray-300 list-disc list-inside">
                    <li>Educational content scripting & development.</li>
                    <li>SME services for all subjects K-12.</li>
                    <li>Content management services.</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Service 3 */}
            <div className="group relative bg-gray-800 transition-all duration-300 ease-in-out hover:z-[1] hover:shadow-xl hover:shadow-gray-600/10 hover:bg-[#1E3A8A]">
              <div className="relative space-y-8 py-12 p-8">
                <img
                  src="https://www.svgrepo.com/show/120852/logo.svg"
                  loading="lazy"
                  width="200"
                  height="200"
                  className="w-16 h-16 rounded-full mx-auto"
                  style={{ color: "transparent" }}
                  alt="Data Management"
                />
                <div className="space-y-2 text-center">
                  <h5 className="text-xl font-semibold text-white transition group-hover:text-[#FFBF00]">
                    Data & Content Management
                  </h5>
                  <ul className="text-gray-300 list-disc list-inside">
                    <li>Database management & disaster recovery.</li>
                    <li>Content storage & management.</li>
                    <li>Software disaster management services.</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Service 4 */}
            <div className="group relative bg-gray-800 transition-all duration-300 ease-in-out hover:z-[1] hover:shadow-xl hover:shadow-gray-600/10 hover:bg-[#1E3A8A]">
              <div className="relative space-y-8 py-12 p-8">
                <img
                  src="https://www.svgrepo.com/show/120850/logo.svg"
                  loading="lazy"
                  width="200"
                  height="200"
                  className="w-16 h-16 rounded-full mx-auto"
                  style={{ color: "transparent" }}
                  alt="Application Services"
                />
                <div className="space-y-2 text-center">
                  <h5 className="text-xl font-semibold text-white transition group-hover:text-[#FFBF00]">
                    Application Services
                  </h5>
                  <p className="text-gray-300">
                    Specializing in website, mobile, and desktop application development, along with software testing and automation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
