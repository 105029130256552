import React, { useRef } from "react";
import Slider from "react-slick";
import card1 from "../assets/2.png";
import card2 from "../assets/3.png";
import card3 from "../assets/4.png";
import card4 from "../assets/Digital.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Blogs() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // Hide default arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      id: 1,
      img: card1,
      title: "E-Learning Services",
      subtitle: "Online Education for Better Learning Outcomes",
      description:
        "Our E-learning Solutions are built around the concept of interactive learning, ensuring that educators and learners experience fostering a more dynamic environment",
    },
    {
      id: 2,
      img: card2,
      title: "Consultation & Graphics",
      subtitle: "Effective Design Solutions for Your Business",
      description:
        "We believe in a collaborative and strategic approach to design. Our process involves understanding your brand, audience, and objectives to create designs",
    },
    {
      id: 3,
      img: card3,
      title: "Search Engine Optimization",
      subtitle: "Maximizing Your Visibility with SEO",
      description:
        "Discover a wealth of multimedia resources, including videos, simulations, and interactive quizzes. Elevate your learning experience with our enriching content",
    },
    {
      id: 4,
      img: card4,
      title: "Digital Marketing",
      subtitle: "Best Practices for Digital Marketing Success",
      description:
        "we're your gateway to the digital landscape. We specialize in understanding the intricacies of digital marketing and ensuring your brand stands out in the digital realm",
    },
  ];

  return (
    <section className="flex flex-col justify-center max-w-7xl min-h-screen px-4 py-10 mx-auto sm:px-6">
      <div className="flex flex-wrap items-center justify-between mb-8">
        <h2 className="mr-10 text-4xl font-bold leading-none md:text-5xl text-[#FFBF00]">
          Things to <span className="text-[#6E6E6E]">know</span>
        </h2>
      </div>

      <Slider ref={sliderRef} {...settings}>
        {cards.map((card) => (
          <div key={card.id} className="px-4">
            <div className="card flex flex-col bg-white border border-[#6E6E6E] rounded-lg overflow-hidden shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl hover:border-[#FFBF00]">
              <img
                src={card.img}
                alt="Card img"
                className="object-cover object-center w-full h-48 transition-opacity duration-300 ease-in-out group-hover:opacity-80"
              />
              <div className="p-6 flex flex-col justify-between">
                <a
                  href="##"
                  className="card-title inline-block mb-4 text-xs font-bold capitalize border-b-2 border-[#FFBF00] hover:text-[#FFBF00]"
                >
                  {card.title}
                </a>
                <a
                  href="##"
                  className="block mb-4 text-2xl font-black leading-tight hover:underline hover:text-[#FFBF00]"
                >
                  {card.subtitle}
                </a>
                <p className="mb-4 text-[#6E6E6E]">{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* Buttons Below the Cards */}
      <div className="flex justify-start px-2 mt-8 space-x-3">
        <button
          className="border-2 border-[#FFBF00] text-white bg-white rounded-full p-3 hover:bg-yellow-100 hover:text-white"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
              stroke="#FFBF00"
            />
          </svg>
        </button>
        <button
          className="border-2 border-[#FFBF00] text-white bg-white rounded-full p-3 hover:bg-yellow-100 hover:text-white"
          onClick={() => sliderRef.current.slickNext()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
              stroke="#FFBF00"
            />
          </svg>
        </button>
      </div>
    </section>
  );
}

export default Blogs;
