import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import FeaturesForm from "../components/FeaturesForm";
import Footer from "../components/Footer";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Blogs from "../components/Blogs";
import Gallery from "../components/Gallery";

function Homepage() {
  return (
    <>
      <Navbar />
      <Hero />
      <section id="mindfeatures">
        <FeaturesForm />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="blogs">
        <Blogs />
      </section>
      <section id="culture">
        <Gallery />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <Footer />
    </>
  );
}

export default Homepage;
