import React from "react";
import featuresbg from "../assets/features-bg.png";

function FeaturesForm() {
  return (
    <div>
      <section className="bg-white">
        <div className="grid max-w-screen-xl px-4 pt-16 pb-10 mx-auto lg:gap-8 xl:gap-0 lg:py-12 lg:grid-cols-12 lg:pt-20">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-8 md:mb-6 lg:mb-6 text-4xl font-semibold leading-none tracking-tight md:text-5xl xl:text-6xl text-[#FFBF00]">
              <span className="text-[#6E6E6E]">About</span> Us
            </h1>

            <p className="max-w-2xl mb-6 font-light text-[#6E6E6E] lg:mb-8 md:text-lg lg:text-xl">
              Our mission is to make learning more accessible, engaging, and impactful for students and educators across the globe.
              <br />
              <br />
              We provide cutting-edge tools and platforms designed to enhance the learning experience. Whether you're a student striving to expand your skills or an educator seeking new ways to engage your learners, we offer solutions that make education smarter, interactive, and more personalized.
              <br />
              <br />
              Our services include interactive online courses, personalized learning paths powered by AI, and gamified content. By harnessing the power of modern technologies, we aim to bridge the gap between traditional education and the digital age.
            </p>

            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a
                href="/contact"
                className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-medium text-center text-white bg-[#FFBF00] hover:bg-yellow-600 focus:ring-4 rounded-lg sm:w-auto"
              >
                Get in Touch
              </a>
            </div>
          </div>

          <div className="mt-8 lg:mt-0 md:mt-0 xl:mt-0 lg:col-span-5 lg:flex">
            <img src={featuresbg} alt="feature" className="h-full w-auto rounded-xl shadow-xl" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default FeaturesForm;
